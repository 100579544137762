import Email from './Email.png';
import FindMy from './Find my.png';
import Instagram from './Instagram.png';
import Computer from './computer.gif';
import './App.css';
import { useState } from 'react';

function App() {
  const [isDialogOpen, setDialogOpen] = useState(0);

  return (
    <div className="App">
      <header className="App-header">
        <h1>NTARCTICA</h1>
        <h1>NTARCTICA</h1>
        <h1>NTARCTICA</h1>
        <h1>NTARCTICA</h1>
        <h1>NTARCTICA</h1>
        <h1>NTARCTICA</h1>
        <h1>NTARCTICA</h1>
        <h1>NTARCTICA</h1>
        <h1>NTARCTICA</h1>
        <h1>NTARCTICA</h1>
        <h1>NTARCTICA</h1>
        <h1>NTARCTICA</h1>
        <h1>NTARCTICA</h1>
        <h1>NTARCTICA</h1>
        <h1>NTARCTICA</h1>
        <h1>NTARCTICA</h1>
        <h1>NTARCTICA</h1>
        <h1>NTARCTICA</h1>
      </header>
      <a className="email-link" href='mailto:mail@ntarcti.ca'><img src={Email} alt="mail"/></a>
      <a className="ig-link" href='https://instagram.com/ntarctica'><img src={Instagram} alt="instagram"/></a>
      <a className="computer-link" href='https://aravind.sh'><img src={Computer} alt="aravind.sh"/></a>
      <img className="find-link" src={FindMy} alt="whereami" onClick={() => setDialogOpen(1)}/>
      {isDialogOpen && (
        <p className="find-text">whereami</p>
      )}
    </div>
  );
}

export default App;
